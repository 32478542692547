<template>
	<div class="wizard-checkout row">

		<div class="wizard-checkout__shipping col-md-3">
				<strong>Ship To:</strong><br>
				{{cart.shipping.firstName}} {{cart.shipping.lastName}}<br>
				{{cart.shipping.street1}} {{cart.shipping.street2}}<br>
				{{cart.shipping.city}}, {{cart.shipping.state}} {{cart.shipping.zip}}
			</div>
			<div class="col-md-9">

				<form class="form wizard-checkout__form row" name="form" @submit.prevent.stop="submitForm()">
					<div class="form-group col-md-12">
						<label for="name">Name on Card</label>
						<input type="text" class="form-control" v-model="params.name" placeholder="Cardholder Name" required autocomplete="cc-name">
					</div>

					<div class="form-group col-md-12">
						<stripe-card @cardError="cardError" @cardChange="cardChange"></stripe-card>
					</div>

					<div class="col-md-12">
						<div class="alert" v-if="hasError || error" :class="{'alert-danger':hasError || error}" v-html="hasError || error"></div>
					</div>
					<button type="submit" class="btn btn-primary center-block" :disabled="hasError || loading || !isReady">Charge {{cart.total | currency}}</button>
				</form>
			</div>

	</div>
</template>

<script>
// Dependencies
import StripeCard from '@/components/StripeCard.vue'

// Component
export default {
	name: 'wizardCheckout',
	components: {
		StripeCard,
	},
	data: () => ({
		isReady: false,
		hasError : null,
		params: {
			name: null,
		},
		card: {
		},
	}),
	computed: {
		cart() {
			return this.$store.getters['cart/cart']
		},
		loading() {
			return this.$store.getters['cart/loading']
		},
		error() {
			return this.$store.getters['cart/error']
		},
	},
	methods: {
		submitForm() {
			const orderData = {
				card: this.card,
				cartId: this.cart.$key,
				params: this.params,
			}
			this.$store.dispatch('cart/createOrder', orderData)
				.then(res => {
					this.$router.push({ name: 'order', params: {orderId: res.key}})
				})
		},
		cardError(err) {
			this.hasError = err.message
		},
		cardChange(evt, card) {
			this.card = card
			this.isReady = evt.complete
		},
	},
}
</script>

<style scoped lang="scss">

</style>
