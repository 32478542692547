<template>
	<div class="stripe-card">
		<label for="card_element">
			Credit Card
		</label>
		<div id="card_element"></div>
		<div id="card_errors" role="alert"></div>
	</div>
</template>

<script>
export default {
	name: 'stripeCard',
	props: {
		styles: {
			type: Object,
			default: () => ({
				base: {
					color: '#495057',
					fontSize: '18px',
					fontFamily: '"Oswald", "Helvetica Neue", Helvetica, sans-serif',
					fontWeight: 400,
				},
			}),
		},
	},
	mounted() {
		const stripe = this.$store.getters['cart/stripe']
		const elements = stripe.elements()
		const card = elements.create('card', {
			style: this.styles,
		})
		card.mount('#card_element')

		card.addEventListener('change', evt => {
			if (evt.error) {
				this.$emit('cardError', evt.error)
			} else {
				this.$emit('cardError', '')
			}
			this.$emit('cardChange', evt, card)
		})
	},
}
</script>

<style scoped lang="scss">
.stripe-card {
	.StripeElement {
		box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
			0px 2px 2px 0px rgba(0, 0, 0, 0.24);
		line-height: 27px;
		padding: 10px;
	}
}
</style>
