<template>
	<div class="wizard-review col-md-12 row">

		<h3 class="text-center section-title">REVIEW YOUR ORDER</h3>
		<form class="form" name="form" @submit.stop.prevent="submitForm(cart)">

			<table class="table">
				<thead>
					<tr>
						<th class="col-sm-8">Product Type</th>
						<th class="col-sm-2">Quantity</th>
						<th class="col-sm-2 text-right">Subtotal</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(product, key) in cart.products" :key="key">
						<td v-html="product.name"></td>
						<td v-html="product.quantity"></td>
						<td class="text-right">{{(product.price * product.quantity) | currency}}</td>
					</tr>
				</tbody>
			</table>

			<table class="table">
				<thead>
					<tr>
						<th class="col-sm-9">Taxes</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><small>If you qualify for a tax exemption, please contact us within 7 business days from the time of your order with a copy of your exemption certificate. After your status has been verified, we will reimburse any applicable taxes paid.</small></td>
						<td class="text-right" colspan="2">{{cart.taxes | currency}}</td>
					</tr>
				</tbody>
			</table>

			<table class="table">
				<thead>
					<tr>
						<th class="col-sm-9">Shipping Type</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{{((cart.shipping || {}).method || {}).carrier_friendly_name || ((cart.shipping || {}).method || {}).carrier}}, {{((cart.shipping || {}).method || {}).standardized_service_name}}
						</td>
						<td class="text-right">{{(((cart.shipping || {}).method || {}).price || 0) | currency}}</td>
					</tr>
				</tbody>
			</table>

			<table class="table">
				<thead>
					<tr>
						<th>Shipping Address</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div class="row">
							<div class="form-group col-md-4">
								<label for="street1">Street Address <small>Required</small></label>
								<input type="text" class="form-control" id="street1" name="street1" v-model="cart.shipping.street1" placeholder="Street Address" required autocomplete="address-line1" @blur="inlineUpdate">
							</div>

							<div class="form-group col-sm-4">
								<label for="street2">Suite <small>(optional)</small></label>
								<input type="text" class="form-control" id="street2" v-model="cart.shipping.street2" placeholder="Suite" autocomplete="address-line2" @blur="inlineUpdate">
							</div>

							<div class="form-group col-sm-4" data-ng-class="{'has-error':form.phone.$invalid && (form.phone.$touched || isSubmitted)}">
								<label for="phone">Phone <small>Required</small></label>
								<!-- <input type="tel" class="form-control" id="phone" name="phone" v-model="cart.shipping.phone" placeholder="(111) 222-3434" data-phone-format required autocomplete="tel" data-ng-blur="inlineUpdate()"> -->
								<input-format type="tel" class="form-control" id="phone" name="phone" v-model="cart.shipping.phone" placeholder="(111) 222-3434" autocomplete="tel" format="phone" @blur="inlineUpdate"></input-format>
							</div>

							<div class="form-group col-sm-4" data-ng-class="{'has-error':form.city.$invalid && (form.city.$touched || isSubmitted)}">
								<label for="city">City <small>Required</small></label>
								<input type="text" class="form-control" id="city" name="city" v-model="cart.shipping.city" placeholder="City" required autocomplete="address-level2" @blur="inlineUpdate">
							</div>

							<div class="form-group col-sm-4" data-ng-class="{'has-error':form.state.$invalid && (form.state.$touched || isSubmitted)}">
								<label for="state">State <small>Required</small></label>
								<div class="select-group">
									<select class="form-control" id="state" name="state" v-model="cart.shipping.state" autocomplete="address-level1" required @blur="inlineUpdate">
										<option :value="undefined">&ndash; Select State &ndash;</option>
										<option :value="state.abbreviation" v-for="(state, key) in statesList" :key="key">{{state.name}}</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4" data-ng-class="{'has-error':form.zip.$invalid && (form.zip.$touched || isSubmitted)}">
								<label for="zip">Zip <small>Required</small></label>
								<input type="text" class="form-control" id="zip" name="zip" v-model="cart.shipping.zip" placeholder="Zip Code" required autocomplete="postal-code" @blur="inlineUpdate" disabled>
							</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="wizard-review__total">
				<h3 class="text-center section-title col-md-12">
					GRAND TOTAL: <span>{{cart.total | currency}}</span>
				</h3>

				<div class="text-center col-md-12">
					<button type="submit" class="btn btn-primary center-block" data-ng-disabled="isLoading">Check Out Now</button>
				</div>
			</div>

		</form>

		<div class="col-12 clearfix line-lg"></div>
		<a href="tel:17274999930"><img src="~@/assets/images/banner-customization.png" width="903" height="256" class="max-width"></a>

	</div>
</template>

<script>
// Dependencies
import InputFormat from '@/components/InputFormat.vue'

// Component
export default {
	name: 'wizardReview',
	components: {
		InputFormat,
	},
	computed: {
		cart() {
			return this.$store.getters['cart/cart']
		},
		statesList() {
			return this.$store.getters['misc/statesList']
		},
	},
	methods: {
		submitForm(cart) {
			this.$store.dispatch('cart/updateShipping', cart.shipping)
				.then(() => {
					this.$router.push({ name: 'checkout' })
				})
		},
		inlineUpdate() {
			this.$store.dispatch('cart/updateShipping', this.cart.shipping)
		},
	},
}
</script>

<style scoped lang="scss">
.wizard-review {

	&__total {
		display: block;
		text-align: center;
		padding: $line-lg 0;
		text-transform: uppercase;
		border-top: 1px solid color_('gray');
		margin-bottom: $line;
		background-color: color_('white');

		span {
			color:color_('brown','light');
			font-weight: 900;
    		letter-spacing: 0;

			// @media screen and (max-width: map-get($grid-breakpoints,'sm')) {
			// 	display: block;
			// }
		}
	}
}
</style>
