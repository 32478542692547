<template>
	<input :value="display" v-on="listeners" v-bind="attrs">
</template>

<script>
// Dependencies
import Vue from 'vue'

// Functions
const masks = {
	currency: {
		mask(val) {
			return Vue.filter('currency')(val)
		},
		unmask(val) {
			val = parseFloat(val.toString().replace(/[^\d\.]/g, ''))
			return isNaN(val) ? 0 : val
		},
	},
	phone: {
		mask(val) {
			return Vue.filter('phone')(val)
		},
		unmask(val) {
			val = parseInt(val.toString().replace(/[^\d]/g, ''))
			return isNaN(val) ? 0 : val
		},
	},
	dob: {
		mask(val) {
			return Vue.filter('dob')(val)
		},
		unmask(val) {
			return val.toString().replace(/[^\d]/g, '')
		},
	},
}

// Component
export default {
	name: 'inputFormat',
	inheritAttrs: false,
	props: {
		value: {
			type: [String, Number, Function],
		},
		format: {
			type: String,
			default: 'currency',
		},
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: evt => this.input(evt),
			}
		},
		attrs() {
			const { ...attrs } = this.$attrs
			return attrs
		},
		display: {
			get() {
				if (!this.value) return this.value
				return this.mask(this.value)
			},
			set(val) {
				this.$emit('input', this.unmask(val))
			},
		},
	},
	methods: {
		input(evt) {
			const pos = Math.max(0, evt.target.selectionStart - 1)
			const len = evt.target.value.length
			this.$emit('input', this.unmask(evt.target.value))
			this.$nextTick(() => {
				const diff = (evt.target.value.length - len)
				evt.target.selectionEnd = pos + diff + 1
			})
		},
		mask(val) {
			return masks[this.format].mask(val)
		},
		unmask(val) {
			return masks[this.format].unmask(val)
		},
	},
}
</script>

<style>

</style>
